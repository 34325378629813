<div class="container">
  <div class="row justify-content-center">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="col-sm-12">
            <h5 class="card-title">Shopping Cart</h5>
            <p class="card-text" *ngIf="!hasItems">There are no items in your shopping cart. Please go back and add some items to your cart</p>
            <div *ngIf="hasItems">
              <p class="card-text">Below are the items that you have selected to be purchased. Please click on the buttons below to select the appropriate action. When you are ready to make a purchase, please click on the Checkout button below.</p>
              <table class="table table-bordered table-condensed table-striped">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Total</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let c of cart">
                    <td>{{ c.name }}</td>
                    <td>
                      <input type="text" class="form-control" id="quantity" (ngModel)="c.quantity" value="{{ c.quantity }}" />
                    </td>
                    <td>{{ c.unitPrice | currency }}</td>
                    <td>{{ c.itemTotal | currency }}</td>
                    <td class="text-right">
                      <button class="btn btn-secondary" (click)="updateItem(c)"><i class="fa fa-plus"></i> Update</button>
                      &nbsp;&nbsp;
                      <button class="btn btn-danger" (click)="deleteItem(c)"><i class="fa fa-remove"></i> Delete</button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-right">
                <button class="btn btn-primary" routerLink="/orders/shipTo"><i class="fa fa-check"></i> Checkout</button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
