export class ShipTo {
  billToId: number;
  companyName: string;
  firstName: string;
  lastName: string;
  address1: string;
  aptNumber: string;
  address2: string;
  city: string;
  stateCode: string;
  postalCode: string;
  countryCode: string;
  phoneNumber: string;
  emailAddress: string;
  carrierId: number;
  shippingMethodId: number;
  courseNumber: string;
  instructorName: string;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
