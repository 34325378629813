<div class="container">
  <div class="row justify-content-center">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Shipping Information</h5>
          <p>
            Please complete the fields below for your shipping address.  If you live in an apartment, please enter your apartment
            number.  This will ensure proper shipping of your order.
          </p>
          <br />
          <form [formGroup]="shipToForm" (ngSubmit)="submit()">
            <!-- <div class="form-group">
              <input type="checkbox" (change)="copyBillToShipTo($event)" />&nbsp;Same as Billing Address
            </div> -->
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Enter School Name" formControlName="companyName" />
            </div>
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Enter Course Number" formControlName="courseNumber" />
            </div>
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Enter Instructor Name" formControlName="instructorName" />
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <input type="text" class="form-control" placeholder="Enter First Name" formControlName="firstName" />
              </div>
              <div class="col-sm-12 col-md-6">
                <input type="text" class="form-control" placeholder="Enter Last Name" formControlName="lastName" />
                <div *ngIf="f.lastName.touched && f.lastName.invalid" class="alert alert-danger">
                  <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                </div>
              </div>
            </div>
            <div class="form-group">

            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <input type="text" class="form-control" placeholder="Enter Address Line #1" formControlName="address1" />
                <div *ngIf="f.address1.touched && f.address1.invalid" class="alert alert-danger">
                  <div *ngIf="f.address1.errors.required">Address Line #1 is required</div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <input type="text" class="form-control" placeholder="Enter Apartment #" formControlName="aptNumber" />
              </div>
            </div>
            <div class="form-group">

            </div>
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Enter Address Line #2" formControlName="address2" />
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-3">
                <input type="text" class="form-control" placeholder="Enter City" formControlName="city" />
                <div *ngIf="f.city.touched && f.city.invalid" class="alert alert-danger">
                  <div *ngIf="f.city.errors.required">City is required</div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <select class="form-control" formControlName="stateCode">
                  <option value="null" selected disabled>--Select State--</option>
                  <option *ngFor="let state of states" [ngValue]="state.stateCode">{{ state.name }}</option>
                </select>
                <div *ngIf="f.stateCode.touched && f.stateCode.invalid" class="alert alert-danger">
                  <div *ngIf="f.stateCode.errors.required">State is required.</div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <input type="text" class="form-control" placeholder="Enter Postal Code" formControlName="postalCode" />
                <div *ngIf="f.postalCode.touched && f.postalCode.invalid" class="alert alert-danger">
                  <div *ngIf="f.postalCode.errors.required">Postal Code is required</div>
                  <div *ngIf="f.postalCode.errors.maxlength">Postal Code cannot be longer than 5 characters.</div>
                  <div *ngIf="f.postalCode.errors.minlength">Postal Code must be 5 characters.</div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <select class="form-control" formControlName="countryCode">
                  <option value="null" selected disabled>--Select Country--</option>
                  <option *ngFor="let country of countries" [ngValue]="country.countryCode">{{ country.name }}</option>
                </select>
                <div *ngIf="f.countryCode.touched && f.countryCode.invalid" class="alert alert-danger">
                  <div *ngIf="f.countryCode.errors.required">Country is required</div>
                </div>
              </div>
            </div>

            <div class="row">
              &nbsp;
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <input type="text" class="form-control" placeholder="Enter Phone Number" formControlName="phoneNumber" />
              </div>
              <div class="col-sm-12 col-md-6">
                <input type="text" class="form-control" placeholder="Enter Email Address" formControlName="emailAddress" />
                <div *ngIf="f.emailAddress.touched && f.emailAddress.invalid" class="alert alert-danger">
                  <div *ngIf="f.emailAddress.errors.email">Email Address must be valid</div>
                </div>
              </div>
            </div>

            <div class="row">
              &nbsp;
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <select class="form-control" formControlName="carrierId">
                  <option value="null" selected disabled>--Select Shipping Carrier--</option>
                  <option *ngFor="let carrier of carriers" ngValue="carrier.id">{{ carrier.name }}</option>
                </select>
              </div>
              <div class="col-sm-12 col-md-6">
                <select class="form-control" formControlName="shippingMethodId">
                  <option value="null" selected disabled>--Select Shipping Method--</option>
                  <option *ngFor="let sm of shippingMethods" ngValue="sm.id">{{ sm.shippingCode }}</option>
                </select>
              </div>
            </div>

            <div class="toolbar">
              <div class="row">
                <div class="col-sm-12 text-right">
                  <!-- <button class="btn btn-primary" routerLink="/orders/billTo"><< Prev</button> -->
                  &nbsp;&nbsp;
                  <button class="btn btn-primary" type="submit" [disabled]="shipToForm.invalid">Next >></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
