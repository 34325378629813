
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { BillTo } from './../../models/bill-to.model';
import { ShipTo } from './../../models/ship-to.model';
import { Country } from './../../models/country.model';
import { StateProvince } from './../../models/state-province.model';
import { ShippingCarrier } from './../../models/shipping-carrier.model';
import { ShippingMethod } from './../../models/shipping-method.model';

import { OrderService } from './../../services/site/order.service';
import { LoggerService } from './../../services/utility/logger.service';
import { SessionOrderService } from './../../services/site/session-order.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from './../../services/site/user.service';

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.scss']
})
export class ShippingComponent implements OnInit {

  shipToForm: FormGroup;
  shipTo: ShipTo;
  billTo: BillTo;
  error = '';
  submitted = false;
  countries: Country[];
  states: StateProvince[];
  carriers: ShippingCarrier[];
  shippingMethods: ShippingMethod[];
  errors = '';

  constructor(
    private formBuilder: FormBuilder,
    private readonly orderService: OrderService,
    private readonly loggerService: LoggerService,
    private readonly router: Router,
    private readonly sessionOrderService: SessionOrderService,
    private readonly toastr: ToastrService,
    private readonly userService: UserService
  ) { }

  ngOnInit(): void {
    this.getCountries();
    this.getStates();
    this.getShippingCarriers();
    this.getShippingMethods();
    this.shipToForm = this.createShipToForm();
    this.getShipTo();
  }

  get f() { return this.shipToForm.controls; }

  createShipToForm() {
    return this.formBuilder.group({
      companyName: [''],
      courseNumber: [''],
      instructorName: [''],
      firstName: [''],
      lastName: ['', Validators.required],
      address1: ['', Validators.required],
      aptNumber: [''],
      address2: [''],
      city: ['', Validators.required],
      stateCode: ['null', Validators.required],
      postalCode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
      countryCode: ['US'],
      phoneNumber: [''],
      emailAddress: ['', Validators.email],
      carrierId: [1],
      shippingMethodId: [1]
    });
  }

  setShipToForm(s: ShipTo) {
    this.shipToForm.setValue({
      companyName: s.companyName ?? '',
      courseNUmber: s.courseNumber ?? '',
      instructorName: s.instructorName ?? '',
      firstName: s.firstName ?? '',
      lastName: s.lastName ?? '',
      address1: s.address1 ?? '',
      aptNumber: s.aptNumber ?? '',
      address2: s.address2 ?? '',
      city: s.city ?? '',
      postalCode: s.postalCode ?? '',
      stateCode: s.stateCode ?? null,
      countryCode: s.countryCode ?? null,
      emailAddress: s.emailAddress ?? '',
      phoneNumber: s.phoneNumber ?? '',
      carrierId: s.carrierId ?? 1,
      shippingMethodId: s.shippingMethodId ?? 1
    });
  }

  getShipTo() {
    const savedShipTo = sessionStorage.getItem('shipTo');
    if (savedShipTo === null || savedShipTo === undefined) {
      console.log('new');
      this.shipTo = new ShipTo();
    } else {
      console.log('from session');
      this.shipTo = JSON.parse(savedShipTo);
    }
    // console.log('shipTo: ' + JSON.stringify(this.shipTo));
    this.setShipToForm(this.shipTo);
  }

  createShipTo() {
    return new ShipTo({
      shipToId: 0,
      companyName: this.f.companyName.value,
      courseNumber: this.f.courseNumber.value ?? 'None',
      instructorName: this.f.instructorName.value ?? 'None',
      firstName: this.f.firstName.value,
      lastName: this.f.lastName.value,
      address1: this.f.address1.value,
      aptNumber: this.f.aptNumber.value,
      address2: this.f.address2.value,
      city: this.f.city.value,
      stateCode: this.f.stateCode.value,
      postalCode: this.f.postalCode.value,
      countryCode: this.f.countryCode.value,
      phoneNumber: this.f.phoneNumber.value,
      emailAddress: this.f.emailAddress.value,
      carrierId: this.f.carrierId.value,
      shippingMethodId: this.f.shippingMethodId.value
    });
  }

  submit() {
    this.submitted = true;

    if (this.shipToForm.invalid) {
      this.toastr.error('All required fields are not completed');
      return;
    }

    this.userService.checkPostalCode(this.f.postalCode.value, this.f.stateCode.value)
      .subscribe(res => {
        if (!res.validated) {
          this.toastr.error(res.errors[0]);
          return;
        } else {
          this.saveShipTo();
          this.router.navigate(['/orders/confirmation']);
        }
      });
  }

  getCountries() {
    this.userService.getCountries()
      .subscribe(data => {
        this.countries = data;
      });
  }

  getStates() {
    this.userService.getStates()
      .subscribe(data => {
        this.states = data;
      });
  }

  getShippingCarriers() {
    this.userService.getShippingCarriers()
      .subscribe(data => {
        this.carriers = data;
      });
  }

  getShippingMethods() {
    this.userService.getShippingMethods()
      .subscribe(data => {
        this.shippingMethods = data;
      });
  }

  getBillTo() {
    const savedBillTo = sessionStorage.getItem('billTo');
    if (savedBillTo === null || savedBillTo === undefined) {
      this.billTo = new BillTo();
    } else {
      this.billTo = JSON.parse(savedBillTo);
    }
  }

  copyBillToShipTo(e) {
    if (e.target.checked) {
      this.getBillTo();
      this.shipTo.companyName = this.billTo.companyName;
      this.shipTo.firstName = this.billTo.firstName;
      this.shipTo.lastName = this.billTo.lastName;
      this.shipTo.address1 = this.billTo.address1;
      this.shipTo.aptNumber = this.billTo.aptNumber;
      this.shipTo.address2 = this.billTo.address2;
      this.shipTo.city = this.billTo.city;
      this.shipTo.postalCode = this.billTo.postalCode;
      this.shipTo.stateCode = this.billTo.stateCode;
      this.shipTo.countryCode = this.billTo.countryCode;
      this.shipTo.emailAddress = this.billTo.emailAddress;
      this.shipTo.phoneNumber = this.billTo.phoneNumber;
      this.setShipToForm(this.shipTo);
    }
  }

  saveShipTo() {
    const shipTo = this.createShipTo();
    sessionStorage.setItem('shipTo', JSON.stringify(shipTo));
  }

}
